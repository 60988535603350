<template>
  <main class="bg-gray-100">
    <section class="section" v-if="!loadError">
      <div class="container">
        <div class="bg-white max-w-sm px-4 py-3 mb-8 shadow">
          <div class="field">
            <label class="label">{{ $t('report_passages_week') }}</label>
          </div>
          <div class="field has-addons">
            <div class="control">
              <sqr-input-week v-model="week" />
            </div>
            <div class="control">
              <sqr-button
                label="buttons_load"
                @click="load()"
                color="primary"
              />
            </div>
          </div>
        </div>
        <sqr-progress v-show="loading" />
        <sqr-error :error="loadError" />
        <div v-if="report">
          <div
            v-for="(groups, day) in report.groupsByDay"
            :key="day"
            class="border-b bg-white shadow hover:shadow-lg mb-8 lg:rounded"
          >
            <div class="lg:flex items-begin">
              <!-- day -->
              <div class="lg:w-2/12 p-4">
                <div class="text-xs uppercase text-gray-500">
                  {{ day | date }}
                </div>
                <div class="text-2xl font-semibold">
                  {{ day | weekDay }}
                </div>
              </div>
              <!-- groups -->
              <div class="lg:w-10/12 lg:flex lg:flex-wrap">
                <div
                  v-for="group in groups"
                  :key="
                    (group.in && group.in.time) || (group.out && group.out.time)
                  "
                  class="flex lg:border-l border-t lg:border-t-0 p-4 lg:px-8 hover:bg-gray-50"
                >
                  <div>
                    <div v-if="group.in" class="text-left">
                      <div class="text-xs uppercase text-gray-500">
                        {{ group.in.source }}
                      </div>
                      <div
                        class="time text-center text-xl font-medium tooltip"
                        :data-tooltip="group.in.time"
                      >
                        {{ group.in.time | time }}
                      </div>
                      <div
                        v-for="other in group.in.others"
                        :key="other.id"
                        class="time text-center text-xs tooltip"
                        :data-tooltip="other.time"
                      >
                        {{ other.time | time }}
                      </div>
                    </div>
                    <div v-else class="text-4xl text-red-700 text-bold">?</div>
                  </div>
                  <div class="text-lg px-8 mt-3">
                    <fa :icon="['fal', 'arrow-right']" />
                  </div>
                  <div>
                    <div v-if="group.out" class="text-right">
                      <div class="text-xs uppercase text-gray-500">
                        {{ group.out.source }}
                      </div>
                      <div
                        class="time text-center text-xl font-medium tooltip"
                        :data-tooltip="group.out.time"
                      >
                        {{ group.out.time | time }}
                      </div>
                      <div
                        v-for="other in group.out.others"
                        :key="other.id"
                        class="time text-center text-xs tooltip"
                        :data-tooltip="other.time"
                      >
                        {{ other.time | time }}
                      </div>
                    </div>
                    <div v-else class="text-4xl text-red-700 text-bold">?</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<style scoped>
.time {
  font-variant-numeric: tabular-nums;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import { groupBy, prop, sortWith, ascend } from 'ramda';

import account from './account';

import date from '@/filters/date';
import weekDay from '@/filters/weekDay';
import time from '@/filters/time';

import SqrInputWeek from '@/sqrd/SqrInputWeek';
import SqrButton from '@/sqrd/SqrButton';
import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';

import groupPassages from 'sqr-wotime-core/passages/groupPassages';


const sortPassages = sortWith([
  ascend(prop('date')),
  ascend(prop('time'))
]);

export default {
  name: 'ReportPassages',
  mixins: [account],
  filters: { date, weekDay, time },
  components: { SqrInputWeek, SqrButton, SqrProgress, SqrError },
  computed: {
    ...mapState('reportPassages', ['record', 'exists', 'loading', 'loadError']),
    report() {
      if (!this.record?.passagesById) return null;
      const passages = Object.values(this.record.passagesById);
      const sorted = sortPassages(passages);
      const { groups, ignored } = groupPassages(sorted);
      const groupsWithDate = groups.map(g => ({
        ...g,
        date: g?.in?.date ?? g?.out?.date,
      }));
      const groupsByDay = groupBy(prop('date'), groupsWithDate);
      return { groupsByDay, ignored };
    },
  },
  data() {
    return {
      week: DateTime.local().toISOWeekDate().substr(0, 8),
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    ...mapActions('reportPassages', ['sub']),
    load() {
      this.sub({
        path: ['companies', this.cid, 'accounts', this.aid, 'passages'],
        id: this.week,
      });
    },
  },
};
</script>
